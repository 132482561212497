// Import Sass mixins, variables, Compass modules, etc.
@import "../_base.scss";
@import "../_layout.scss";
@import "../_theme.scss";
@import "../_mixins.scss";


$nav-color: $nav;


/* Behavior
   ========================================================================= */

.js .region-navigation {
  @include breakpoint($tablet-portrait-max) {
    overflow: hidden;
    @include transition(max-height .5s cubic-bezier(.17,.67,.66,1.04));
    max-height: 0;
  }
}

.show-nav .region-navigation {
  max-height: 30em;
}

#toggle-nav {
  float: left;
  @include breakpoint($tablet-portrait-min) {
    display: none;
  }
}


/* Theme
   ========================================================================= */


/*
 * Toggle Button
 */

.nav-button {
  display: block;
  width: 42px;
  height: 36px;
  cursor: pointer;
}


.nav-button {
  background-color: transparent;
  color: $nav-color;
  display: block;
  font-size: 1.5em;
  font-weight: bold;
  padding: 7px 5px;
  @include border-radius($ui-border-radius);
  line-height: 18px;
  margin-bottom: 0;
  text-align: center;
  &:hover, &:active {
    color: lighten($nav-color, 20%);
    .toggle-bar {
      background-color: lighten($nav-color, 20%);
    }
  }
}

.nav-button .bar {
  background-color: #fff;
  @include border-radius(1px);
  display: block;
  margin-top: 0;
  height: 3px;
  width: 32px;
  line-height: 14px;
  vertical-align: text-top;
}

.nav-button .bar + .bar {
  margin-top: 6px;
}

/*
 * Navigation Links
 */

.navigation {
  width:100%;
  clear: both;
  float: none;
  background: $nav-color;
  .block {
    margin-bottom: 0;
  }
  ul.menu{
    position:relative;
    li {
      float: none;
      text-align: center;
      border-bottom: 1px solid lighten($nav-color, 10%);
      margin-bottom: 0;
      position:relative;
      letter-spacing:1px;
      // &.first {
      //   border-top:1px solid lighten($nav-color, 10%);
      // }
      &.last {
        border-bottom: none;
      }
      a {
        border-bottom:none;
        padding: 1em;
        display: block;
        text-align: center;
        color: #fff;
        font-family:$body-font;
        @include single-text-shadow(darken($nav-color, 20%) 0 0 1px);
        &:hover {
          background-color: $nav-hover;
        }
        &:active {
          background: $nav-hover;
        }

      }
      ul {
        display:none;
        position:absolute;
        left:0;
        background-color:$nav-hover;
        min-width:200px;
        z-index:110;
        padding-bottom:$horiz-gutter;
        li {
          letter-spacing:0;
          display:block;
          text-align:left;
          padding:0;
          margin-right:0;
          &:nth-child(7),
          &:nth-child(8) {
            padding:0;
            a:hover {
              background-color:$nav-color;
            }
          }
          a {
            text-align:left;
            padding:12px 20px;
            &:hover {
              background-color:$nav-color;
            }
          }
        }
      }
    }
  }
}

/*
 * Desktop Styles
 */

@include breakpoint($tablet-portrait-min) {
  .navigation {
    margin-left: 0;
    margin-right: 0;
    clear: none;
    background: none;

    .block-menu .block-title,
    .block-menu-block .block-title {
      @include element-invisible();
    }

    ul.links, /* Main menu and secondary menu links */
    ul.menu { /* Menu block links */
      margin: 0;
      padding: 0;
      text-align: left; /* LTR */
      @include clearfix;

      li { /* A simple method to get navigation links to appear in one line. */
        display:inline-block;
        list-style-type: none;
        list-style-image: none;
        border-bottom: none;
        // padding:0 8px 0 0;
        margin-right:-4px;
        a {
          color: #fff;
          padding:28px 17px 28px 17px;
          display:block;
          font-size:14px;
          &:hover {
            background-color: $nav-hover;
            color: #fff;
          }
          &:active {
            background-color:$nav-hover;
            color: #fff;
          }
        }
        &.expanded {
          a {
            // background-image:url('../images/dropdown-arrow.png');
            // background-repeat:no-repeat;
            // background-position:right center;
            // background-size:11px auto;
            // padding:19px 12px 19px 12px;
          }
          ul li {
            a {
              background-image:none;
              padding:10px 20px !important;
            }
          }
        }
        ul {
          li {
            &:nth-child(7),
            &:nth-child(8) {
              float:none;
              position:static;
            }
          }
        }
        &:nth-child(7) {
          background-color:$nav-hover;
          // padding:0 20px 0 8px;
          margin-right:0;
          &:hover,
          ul {
            background-color:$nav-hover;
          }
          ul {
            li {
              a:hover {
                background-color:$nav-hover;
              }
            }
          }
          // a {
          //   background-image:url('../images/dropdown-arrow-white.png');
          //   background-repeat:no-repeat;
          //   background-position:right center;
          //   background-size:11px auto;
          // }
          a:hover {
            background-color:transparent;
          }
        }
        &:nth-child(8) {
          background-color:$nav-quick-links;
          // padding:0 20px 0 8px;
          position:absolute;
          right:0;
          top:0;
          margin-right:0;
          &:hover,
          ul {
            background-color:$nav-quick-links;
          }
          ul {
            position:absolute;
            right:0;
            left:auto;
            li,
            li:nth-child(7),
            li:nth-child(8) {
              a:hover {
                background-color:darken($nav-quick-links, 10%) !important;
              }
            }
          }
          // a {
          //   background-image:url('../images/dropdown-arrow-white.png');
          //   background-repeat:no-repeat;
          //   background-position:right center;
          //   background-size:11px auto;
          // }
          a:hover {
            background-color:transparent;
          }
        }
        &.first {
          border-top:none;
        }
        &.last a {
          border: none;
        }
        &:hover {
          background-color:$nav-hover;
          ul {
            display:block;
            li {
              a {
                &:hover {
                  background-color:$nav-color;
                }
              }
            }
            li:nth-child(7) {
              background-color:transparent;
              a {
                padding:12px 20px;
              }
              // a:hover {
              //   background-color:$header-blue;
              // }
            }
            li:nth-child(8) {
              background-color:transparent;
              a {
                padding:12px 20px;
              }
              // a:hover {
              //   background-color:$header-blue;
              // }
            }
          }
        }
      }
    }
  }
}

@include breakpoint($tablet-landscape-min) {
  .navigation {
    ul.menu {
      li {
        height:60px;
        line-height:60px;
        vertical-align:middle;
        padding:0;
        max-width:250px;
        a {
          padding:0 0.95em !important;
          line-height:20px;
          display:inline-block;
          vertical-align:middle;
          font-size:14px;
        }
        ul li {
          width:100%;
          height:auto;
          a {
            display:block;
            font-size:14px;
            // padding:0 !important;
          }
        }
        &:nth-child(7) {
          position:absolute;
          right:118px;
          top:0;
        }
        &:nth-child(8) {
          width:118px;
        }
        ul li {
          width:auto !important;
        }
      }
    }
  }
}

@include breakpoint($tablet-landscape-max $tablet-portrait-min) {
  .navigation {
    ul.menu {
      li {
        width:16.6%;
        height:60px;
        line-height:60px;
        vertical-align:middle;
        padding:0;
        a {
          padding:0 1em !important;
          line-height:20px;
          display:inline-block;
          vertical-align:middle;
          font-size:14px;
        }
        &:nth-child(6) {
          ul {
            position:absolute;
            right:0;
            left:auto;
          }
        }
        &:nth-child(7) {
          position:absolute;
          top:-60px;
          right:131px;
          height:60px;
          line-height:60px;
          width:132px;
          ul {
            position:absolute;
            right:0;
            left:auto;
          }
        }
        &:nth-child(8) {
          position:absolute;
          top:-60px;
          right:0px;
          // z-index:99;
          height:60px;
          line-height:60px;
          width:131px;
        }
        ul li {
          width:100%;
          height:auto;
          a {
            display:block;
            font-size:14px;
          }
          &:nth-child(8) {
            position:static;
          }
        }
      }
    }
  }
}

@include breakpoint($tablet-portrait-max) {
  .navigation {
    .block-menu .block-title,
    .block-menu-block .block-title {
      @include element-invisible();
    }
    ul.links, /* Main menu and secondary menu links */
    ul.menu { /* Menu block links */
      li { /* A simple method to get navigation links to appear in one line. */
        display:block;
      }
    }
  }
}


