/* Behavior
   ========================================================================= */
@media (max-width: 47.9375em) {
  .js .region-navigation {
    overflow: hidden;
    -webkit-transition: compact(compact(max-height 0.5s cubic-bezier(0.17, 0.67, 0.66, 1.04), false, false, false, false, false, false, false, false, false) false false);
    -moz-transition: compact(compact(max-height 0.5s cubic-bezier(0.17, 0.67, 0.66, 1.04), false, false, false, false, false, false, false, false, false) false false false);
    -o-transition: compact(compact(max-height 0.5s cubic-bezier(0.17, 0.67, 0.66, 1.04), false, false, false, false, false, false, false, false, false) false false false);
    transition: compact(max-height 0.5s cubic-bezier(0.17, 0.67, 0.66, 1.04), false, false, false, false, false, false, false, false, false);
    max-height: 0;
  }
}

.show-nav .region-navigation {
  max-height: 30em;
}

#toggle-nav {
  float: left;
}

@media (min-width: 48em) {
  #toggle-nav {
    display: none;
  }
}

/* Theme
   ========================================================================= */
/*
 * Toggle Button
 */
.nav-button {
  display: block;
  width: 42px;
  height: 36px;
  cursor: pointer;
}

.nav-button {
  background-color: transparent;
  color: #1E4679;
  display: block;
  font-size: 1.5em;
  font-weight: bold;
  padding: 7px 5px;
  -webkit-border-radius: 1.749993px;
  -moz-border-radius: 1.749993px;
  -ms-border-radius: 1.749993px;
  -o-border-radius: 1.749993px;
  border-radius: 1.749993px;
  line-height: 18px;
  margin-bottom: 0;
  text-align: center;
}

.nav-button:hover, .nav-button:active {
  color: #3275cb;
}

.nav-button:hover .toggle-bar, .nav-button:active .toggle-bar {
  background-color: #3275cb;
}

.nav-button .bar {
  background-color: #fff;
  -webkit-border-radius: 1px;
  -moz-border-radius: 1px;
  -ms-border-radius: 1px;
  -o-border-radius: 1px;
  border-radius: 1px;
  display: block;
  margin-top: 0;
  height: 3px;
  width: 32px;
  line-height: 14px;
  vertical-align: text-top;
}

.nav-button .bar + .bar {
  margin-top: 6px;
}

/*
 * Navigation Links
 */
.navigation {
  width: 100%;
  clear: both;
  float: none;
  background: #1E4679;
}

.navigation .block {
  margin-bottom: 0;
}

.navigation ul.menu {
  position: relative;
}

.navigation ul.menu li {
  float: none;
  text-align: center;
  border-bottom: 1px solid #285ea2;
  margin-bottom: 0;
  position: relative;
  letter-spacing: 1px;
}

.navigation ul.menu li.last {
  border-bottom: none;
}

.navigation ul.menu li a {
  border-bottom: none;
  padding: 1em;
  display: block;
  text-align: center;
  color: #fff;
  font-family: Verdana, Tahoma, "DejaVu Sans", sans-serif;
  text-shadow: compact(compact(#0a1727 0 0 1px 0px 1px false #aaa), false, false, false, false, false, false, false, false, false);
}

.navigation ul.menu li a:hover {
  background-color: #1E7CBC;
}

.navigation ul.menu li a:active {
  background: #1E7CBC;
}

.navigation ul.menu li ul {
  display: none;
  position: absolute;
  left: 0;
  background-color: #1E7CBC;
  min-width: 200px;
  z-index: 110;
  padding-bottom: 15px;
}

.navigation ul.menu li ul li {
  letter-spacing: 0;
  display: block;
  text-align: left;
  padding: 0;
  margin-right: 0;
}

.navigation ul.menu li ul li:nth-child(7), .navigation ul.menu li ul li:nth-child(8) {
  padding: 0;
}

.navigation ul.menu li ul li:nth-child(7) a:hover, .navigation ul.menu li ul li:nth-child(8) a:hover {
  background-color: #1E4679;
}

.navigation ul.menu li ul li a {
  text-align: left;
  padding: 12px 20px;
}

.navigation ul.menu li ul li a:hover {
  background-color: #1E4679;
}

/*
 * Desktop Styles
 */
@media (min-width: 48em) {
  .navigation {
    margin-left: 0;
    margin-right: 0;
    clear: none;
    background: none;
  }
  .navigation .block-menu .block-title,
  .navigation .block-menu-block .block-title {
    position: absolute !important;
    height: 1px;
    width: 1px;
    overflow: hidden;
    clip: rect(1px, 1px, 1px, 1px);
  }
  .navigation ul.links,
  .navigation ul.menu {
    /* Menu block links */
    margin: 0;
    padding: 0;
    text-align: left;
    /* LTR */
    overflow: hidden;
  }
  .navigation ul.links li,
  .navigation ul.menu li {
    /* A simple method to get navigation links to appear in one line. */
    display: inline-block;
    list-style-type: none;
    list-style-image: none;
    border-bottom: none;
    margin-right: -4px;
  }
  .navigation ul.links li a,
  .navigation ul.menu li a {
    color: #fff;
    padding: 28px 17px 28px 17px;
    display: block;
    font-size: 14px;
  }
  .navigation ul.links li a:hover,
  .navigation ul.menu li a:hover {
    background-color: #1E7CBC;
    color: #fff;
  }
  .navigation ul.links li a:active,
  .navigation ul.menu li a:active {
    background-color: #1E7CBC;
    color: #fff;
  }
  .navigation ul.links li.expanded ul li a,
  .navigation ul.menu li.expanded ul li a {
    background-image: none;
    padding: 10px 20px !important;
  }
  .navigation ul.links li ul li:nth-child(7), .navigation ul.links li ul li:nth-child(8),
  .navigation ul.menu li ul li:nth-child(7),
  .navigation ul.menu li ul li:nth-child(8) {
    float: none;
    position: static;
  }
  .navigation ul.links li:nth-child(7),
  .navigation ul.menu li:nth-child(7) {
    background-color: #1E7CBC;
    margin-right: 0;
  }
  .navigation ul.links li:nth-child(7):hover,
  .navigation ul.links li:nth-child(7) ul,
  .navigation ul.menu li:nth-child(7):hover,
  .navigation ul.menu li:nth-child(7) ul {
    background-color: #1E7CBC;
  }
  .navigation ul.links li:nth-child(7) ul li a:hover,
  .navigation ul.menu li:nth-child(7) ul li a:hover {
    background-color: #1E7CBC;
  }
  .navigation ul.links li:nth-child(7) a:hover,
  .navigation ul.menu li:nth-child(7) a:hover {
    background-color: transparent;
  }
  .navigation ul.links li:nth-child(8),
  .navigation ul.menu li:nth-child(8) {
    background-color: #78458D;
    position: absolute;
    right: 0;
    top: 0;
    margin-right: 0;
  }
  .navigation ul.links li:nth-child(8):hover,
  .navigation ul.links li:nth-child(8) ul,
  .navigation ul.menu li:nth-child(8):hover,
  .navigation ul.menu li:nth-child(8) ul {
    background-color: #78458D;
  }
  .navigation ul.links li:nth-child(8) ul,
  .navigation ul.menu li:nth-child(8) ul {
    position: absolute;
    right: 0;
    left: auto;
  }
  .navigation ul.links li:nth-child(8) ul li a:hover,
  .navigation ul.links li:nth-child(8) ul li:nth-child(7) a:hover,
  .navigation ul.links li:nth-child(8) ul li:nth-child(8) a:hover,
  .navigation ul.menu li:nth-child(8) ul li a:hover,
  .navigation ul.menu li:nth-child(8) ul li:nth-child(7) a:hover,
  .navigation ul.menu li:nth-child(8) ul li:nth-child(8) a:hover {
    background-color: #5b346b !important;
  }
  .navigation ul.links li:nth-child(8) a:hover,
  .navigation ul.menu li:nth-child(8) a:hover {
    background-color: transparent;
  }
  .navigation ul.links li.first,
  .navigation ul.menu li.first {
    border-top: none;
  }
  .navigation ul.links li.last a,
  .navigation ul.menu li.last a {
    border: none;
  }
  .navigation ul.links li:hover,
  .navigation ul.menu li:hover {
    background-color: #1E7CBC;
  }
  .navigation ul.links li:hover ul,
  .navigation ul.menu li:hover ul {
    display: block;
  }
  .navigation ul.links li:hover ul li a:hover,
  .navigation ul.menu li:hover ul li a:hover {
    background-color: #1E4679;
  }
  .navigation ul.links li:hover ul li:nth-child(7),
  .navigation ul.menu li:hover ul li:nth-child(7) {
    background-color: transparent;
  }
  .navigation ul.links li:hover ul li:nth-child(7) a,
  .navigation ul.menu li:hover ul li:nth-child(7) a {
    padding: 12px 20px;
  }
  .navigation ul.links li:hover ul li:nth-child(8),
  .navigation ul.menu li:hover ul li:nth-child(8) {
    background-color: transparent;
  }
  .navigation ul.links li:hover ul li:nth-child(8) a,
  .navigation ul.menu li:hover ul li:nth-child(8) a {
    padding: 12px 20px;
  }
}

@media (min-width: 64em) {
  .navigation ul.menu li {
    height: 60px;
    line-height: 60px;
    vertical-align: middle;
    padding: 0;
    max-width: 250px;
  }
  .navigation ul.menu li a {
    padding: 0 0.95em !important;
    line-height: 20px;
    display: inline-block;
    vertical-align: middle;
    font-size: 14px;
  }
  .navigation ul.menu li ul li {
    width: 100%;
    height: auto;
  }
  .navigation ul.menu li ul li a {
    display: block;
    font-size: 14px;
  }
  .navigation ul.menu li:nth-child(7) {
    position: absolute;
    right: 118px;
    top: 0;
  }
  .navigation ul.menu li:nth-child(8) {
    width: 118px;
  }
  .navigation ul.menu li ul li {
    width: auto !important;
  }
}

@media (max-width: 63.9375em) and (min-width: 48em) {
  .navigation ul.menu li {
    width: 16.6%;
    height: 60px;
    line-height: 60px;
    vertical-align: middle;
    padding: 0;
  }
  .navigation ul.menu li a {
    padding: 0 1em !important;
    line-height: 20px;
    display: inline-block;
    vertical-align: middle;
    font-size: 14px;
  }
  .navigation ul.menu li:nth-child(6) ul {
    position: absolute;
    right: 0;
    left: auto;
  }
  .navigation ul.menu li:nth-child(7) {
    position: absolute;
    top: -60px;
    right: 131px;
    height: 60px;
    line-height: 60px;
    width: 132px;
  }
  .navigation ul.menu li:nth-child(7) ul {
    position: absolute;
    right: 0;
    left: auto;
  }
  .navigation ul.menu li:nth-child(8) {
    position: absolute;
    top: -60px;
    right: 0px;
    height: 60px;
    line-height: 60px;
    width: 131px;
  }
  .navigation ul.menu li ul li {
    width: 100%;
    height: auto;
  }
  .navigation ul.menu li ul li a {
    display: block;
    font-size: 14px;
  }
  .navigation ul.menu li ul li:nth-child(8) {
    position: static;
  }
}

@media (max-width: 47.9375em) {
  .navigation .block-menu .block-title,
  .navigation .block-menu-block .block-title {
    position: absolute !important;
    height: 1px;
    width: 1px;
    overflow: hidden;
    clip: rect(1px, 1px, 1px, 1px);
  }
  .navigation ul.links,
  .navigation ul.menu {
    /* Menu block links */
  }
  .navigation ul.links li,
  .navigation ul.menu li {
    /* A simple method to get navigation links to appear in one line. */
    display: block;
  }
}
