


// =============================================================================
// SUPPORT VARIABLES (IE and others... compass/support variable)
// =============================================================================



// These variables are used by many mixins to add additional CSS to support
// specific versions of IE or specific vendor prefixes.
//
// To turn on support for IE6 or IE7, set those variables to true.
//
// Zen does not require special handling for IE8 or later. But Compass uses that
// variable for a couple edge cases. We include it for completeness sake. See
// the documentation at http://compass-style.org/reference/compass/support/
// Variable source: Zen
$legacy-support-for-ie6: false;
$legacy-support-for-ie7: false;
// Variable source: Compass (deprecated)
$legacy-support-for-ie8: true;



// =============================================================================
// TYPOGRAPHY
// =============================================================================



// Adjust with copy paste of pixel css code from http://www.gridlover.net/app/
$base-font-size:   14px; // The font size set on the root html element.
$measure: 760px;

$base-font-size: 14px;
$base-line-height: 21px;
$measure: 760px;

$h1-font-size: 36px;
$h1-line: 21px;
$h1-lines: 2.1;
$h1-line-height: $h1-line * $h1-lines;
$h1-margin-top: 2 * $h1-line;
$h1-margin-bottom: 1 * $h1-line;

$h2-font-size: 28px;
$h2-line: 21px;
$h2-lines: 1.7;
$h2-line-height: $h2-line * $h2-lines;
$h2-margin-top: 2 * $h2-line;
$h2-margin-bottom: 1 * $h2-line;

$h3-font-size: 22px;
$h3-line: 21px;
$h3-lines: 1.8;
$h3-line-height: $h3-line * $h3-lines;
$h3-margin-top: 1 * $h3-line;
$h3-margin-bottom: 0.5 * $h3-line;

$h4-font-size: 18px;
$h4-line: 21px;
$h4-lines: 1;
$h4-line-height: $h4-line * $h4-lines;
$h4-margin-top: 1 * $h4-line;
$h4-margin-bottom: 7.5px;

// Variables are rounded to pixels
// It's safe to multiply with integers
// eg. h1 {margin: 2*$h1-line 0;}
// The grid will stay aligned


// Recalculating line-heights to remove units
$h1-line-height: ($h1-line * $h1-lines) / $h1-font-size;
$h2-line-height: ($h2-line * $h2-lines) / $h2-font-size;
$h3-line-height: 1.2;
$h4-line-height: 1.6;

$body-line-height: 21px;


// Example use:
// Use (@include h1;) to match font size and line height.
// If you need the same margins, extend the header selector instead of the placeholder. (@extend h1)
@mixin h1 {
  @include rem('font-size', $h1-font-size);
  line-height: $h1-line-height;
}
@mixin h2 {
  @include rem('font-size', $h2-font-size);
  line-height: $h2-line-height;
}
@mixin h3 {
  @include rem('font-size', $h3-font-size);
  line-height: $h3-line-height;
}
@mixin h4 {
  @include rem('font-size', $h4-font-size);
  line-height: $h2-line-height;
}

// The following font family declarations are based on the Microsoft core web
// fonts which are common fonts available on most computer systems. The DejaVu
// and Nimbus Sans fonts are commonly available on Linux systems where the MS
// fonts are less common. Tahoma and Helvetica are also widely available.
//
// A user's web browser will look at the comma-separated list and will
// attempt to use each font in turn until it finds one that is available
// on the user's computer. The final "generic" font (sans-serif, serif or
// monospace) hints at what type of font to use if the web browser doesn't
// find any of the fonts in the list.

// Common font stacks
$times-new-roman: "Times New Roman", Times, Georgia, "DejaVu Serif", serif;
$times:           Times, "Times New Roman", Georgia, "DejaVu Serif", serif;
$georgia:         Georgia, "Times New Roman", "DejaVu Serif", serif;

$verdana:         Verdana, Tahoma, "DejaVu Sans", sans-serif;
$tahoma:          Tahoma, Verdana, "DejaVu Sans", sans-serif;
$arial:           Arial, Helvetica, "Nimbus Sans L", sans-serif;
$sans-serif:     "HelveticaNeue-Light", "Helvetica Neue Light", "Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif;

$webfont-normal:      Arial, Helvetica, sans-serif;
$webfont-bold:        Arial, Helvetica, sans-serif;

$courier:         monospace, "Courier New", "DejaVu Sans Mono", sans-serif;

$utopia: "utopia-std-subhead",georgia,serif;
$proxima: "proxima-nova-alt",verdana,sans-serif;
$calluna: "calluna-sans", verdana, sans-serif;
$lemonde: "lemonde-journal", georgia, serif;
$myriad: "myriad-pro", verdana, sans-serif;

// Now create some variables for the font stacks we want to use on this site.
$header-font: $proxima;
$page-title-font: $utopia;
$block-title-font: $proxima;
$body-font: $verdana;
$font-monospace:   $courier;



// =============================================================================
// THEME COLORS
// =============================================================================



// UI THEME VARIABLES

$header-blue:#00539B;
$header-red:#B30425;
$nav:#1E4679;
$nav-hover:#1E7CBC;
$nav-quick-links:#78458D;
$nav-audience:#25aae1;
$testimonial-bgrd:#0D67A8;
$feature-bgrd:#F9D270;
$callout-bgrd:#FBF6E4;
$table-header-bgrd:#FEEDA9;
$slider-bgrd:#00529b;
$feed-bgrd:$slider-bgrd;
$related-feature-bgrd: $nav-hover;
$related-sidebar-bgrd: $nav-quick-links;
$popular-bgrd:$nav-quick-links;
$footer-bgrd:#DBDAE0;
$rule-color:#646464;
$light-rule-color:#aaaaaa;
$see-more-btn:#0D67A8;
$accordion-bgrd:$nav-hover;
$finder-bgrd:$table-header-bgrd;
$med-gray: #c4c4c4;
$dark-gray: #545456;
$black: #000;

$primary-color: $header-blue;
$delete-color: #e02443;
$error-color: $delete-color;

$primary-button-color: $header-blue;
$page-title-color: $header-blue;
$block-title-color: $header-red;
$body-font-color: #000000;
$body-bgrd-color: #ffffff;
$content-bgrd-color: #fcf9e6;
$card-bgrd-color: #F4F2F2;

$disabled-background-color: #eeeeee;
$disabled-font-color: #888888;

// Colorin on the Go Colors
$teal: #0f8099;
$green: #56a540;
$purple: #7d349b;
$orange: #f56905;

// LINKS
$link-color: #B7531C;
$link-color-visited: #D8500B;
$link-color-hover: darken($link-color, 10%);
$link-color-focus: darken($link-color, 10%);
$link-color-active: darken($link-color, 10%);
$link-text-decoration: none;
$language-button-color:#B30425;

// BORDERS
$thin-border: 1px solid $black;
$thick-border: 3px solid $black;
$shadow-color: rgba(92, 95, 97, 0.25);
$box-shadow: 0 4px 12px $shadow-color;


// =============================================================================
// FORM ELEMENTS
// =============================================================================



// GENERAL FORM/FIELDSET VARIABLES
$ui-border-color: lighten($link-color, 28%);
$ui-background-color: lighten($link-color, 32%);
$ui-padding: $base-line-height;
$ui-border-radius: .083333  * $base-line-height;

$fieldset-background-color: $ui-background-color;
$fieldset-border-color: $ui-border-color;
$fieldset-border-radius: $ui-border-radius;
$fieldset-padding: $ui-padding;
$fieldset-border-style: solid;
$legend-font-weight: bold;

$horizontal-rule-border: $ui-border-color;

// FORM ITEMS
$form-item-margin: 0 0 $horiz-gutter 0;

$form-item-label-font-weight: bold;
$form-item-label-font-size: 13px;
$form-item-label-margin: 0 0 0 0;

$form-item-description-font-size: 13px;


// FORM INPUTS, BUTTONS, SUBMITS & PAGERS
$input-padding: 8px 16px;
$input-font-family: inherit;
$input-font-color: #898888;
$input-font-size: 14px;

$input-background-color: #eae8e8;
$input-backgroud-color-focus: #fff;

$input-border-color: transparent;
$input-border-color-focus: $header-blue;
$input-border-radius: 0;

$input-box-shadow: none;
$input-box-shadow-focus: 0 0 2px $link-color;
$input-focus-transition-timing: .1s;

$input-disabled-background-color: $disabled-background-color;
$input-disabled-font-color: $disabled-font-color;

$width-small: 60px;
$width-medium: 120px;
$width-large: 220px;
$width-xlarge: 320px;
$width-xxlarge: 100%;
$inline-field-gutter: .5em;

$placeholder-color: #898888;
$placeholder-focus-color: $input-background-color;

$pager-color: $link-color;
$pager-color-hover: $link-color-hover;

$button-color: $link-color;
$button-border: #DDDDDD;
$button-border-radius: 4px;


// TABLES

$table-border-style: solid;
$table-border-width: 0;
$table-border-color:#ccc;


$table-tbody-border-style: none;
$table-tbody-border-width: 0;
$table-tbody-border-color: transparent;

$table-th-border-style: solid;
$table-th-border-width: 1px 1px 1px 1px;
$table-th-border-color: transparent;
$table-th-bgrd-color:$primary-color;

$table-tr-even-background: #fff;
$table-tr-even-border-style: solid;
$table-tr-even-border-width: 1px;
$table-tr-even-border-color: transparent;

$table-tr-odd-background: transparent;
$table-tr-odd-border-style: solid;
$table-tr-odd-border-width: 1px 1px 1px 1px;
$table-tr-odd-border-color: darken($body-bgrd-color, 2%) transparent transparent transparent;

$table-tr-drag-background: $table-tr-odd-border-color;
$table-td-padding: .5em 1em;
$table-td-disabled-background: #ccc;

// Darken table when in a ui-block
$ui-table-th-border-color: darken($ui-background-color, 3%);
$ui-table-tbody-border-color: darken($ui-background-color, 3%);
$ui-table-tr-even-background: darken($ui-background-color, 3%);
$ui-table-tr-even-border-color: darken($ui-background-color, 3%);
$ui-table-tr-odd-background: transparent;
$ui-table-tr-odd-border-color: darken($ui-background-color, 3%);
$ui-table-tr-drag-background: $ui-table-tr-odd-border-color;


// DRUPAL VERTICAL & HORIZONTAL TABS
$tabs-display-background-color: $ui-background-color;
$tabs-display-border-color: $ui-border-color;
$tabs-display-active-border-color: $link-color;
$tabs-display-active-content-color: lighten($tabs-display-border-color, 20%);
$tabs-display-border-radius: $ui-border-radius;


// DRUPAL EDIT TABS
$tabs-edit-container-background-color: #ffffff;
$tabs-edit-background-color: $ui-background-color;
$tabs-edit-border-color: $ui-border-color;
$tabs-edit-border-radius: $ui-border-radius;


// LISTS, BLOCKQUOTES & COMMENTS
$indent-amount: 1.5 * $base-line-height;
$blockquote-border-color: $ui-border-color;

